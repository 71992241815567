$(document).ready(function() {
  $('.content-5-img-container').mouseover(function(event) {
    $('.content-5-img-container').addClass('blur-img');
    $(this).removeClass('blur-img');
  });
  $('.content-5-img-container').mouseout(function(event) {
    $('.content-5-img-container').addClass('blur-img');
    $('.content-5-i3').removeClass('blur-img');
  })

  $(window).scroll(function() {
    if ($(window).scrollTop() + window.innerHeight > $('.content-s-5').offset().top + $('.content-c-5').height()) {
      $('.content-5-i1').addClass('content-5-i1-trans');
      $('.content-5-i2').addClass('content-5-i2-trans');
      $('.content-5-i3').addClass('content-5-i3-trans');
    } else {
      $('.content-5-i1').removeClass('content-5-i1-trans');
      $('.content-5-i2').removeClass('content-5-i2-trans');
      $('.content-5-i3').removeClass('content-5-i3-trans');
    }
  });

  var video = $('#pop-video').get(0);
  $('#pop-video').click(function(event){
    event.stopPropagation();
    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  })
});

function test() {
  document.getElementById("pop-video").play();
}

function downloadAPP() {
  _hmt.push(['_trackEvent', 'm', 'click', 'downloadAPP']);
  window.location.href = "https://app.download.meng-dong.com";
}

function removeMask() {
  var video = $('#pop-video').get(0);
  video.pause();
  $('.buy-pop-container').hide();
  $('.video-pop-container').hide();
  $('.mask-container').fadeOut();
}

function pcBuyMd() {
  _hmt.push(['_trackEvent', 'pc', 'click', 'buy']);
  $('.buy-pop-container').show();
  $('.mask-container').fadeIn();
}

function showAdVideo() {
  $('#pop-video').get(0).poster = "img/ad.jpg";
  $('#pop-video').get(0).src = "http://ef-operate.oss-cn-qingdao.aliyuncs.com/website/product_ad.mp4";
  $('.video-pop-container').show();
  $('.mask-container').fadeIn();
}

function showUseVideo() {
  $('#pop-video').get(0).poster = "img/use.jpg";
  $('#pop-video').get(0).src = "http://ef-operate.oss-cn-qingdao.aliyuncs.com/website/product_use.mp4";
  $('.video-pop-container').show();
  $('.mask-container').fadeIn();
}